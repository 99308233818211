import React from 'react';
import './Privacy.scss';

function Privacy(props) {
    var googlePolicy = <a className='link_privacy' href={'https://policies.google.com/technologies/partner-sites'}>here</a>;
    var back = <a className='link_privacy' href={'/'}>Back to Fuzzynoise.com</a>

    return (
        <div>
            <div className='main_privacy'>
                <h1 className='title_privacy'>Fuzzynoise.com/privacy</h1>
                <h2 className='text_privacy'>Our Privacy Policy</h2>

                <h4 className='miniText_privacy'>Fuzzynoise.com does not collect or store any personal data.</h4>
                <h4 className='miniText_privacy'>Fuzzynoise.com uses Google Firebase for analytics.</h4>
                <p className='paragraph_privacy'>The data collected by Fuzzynoise.com through Firebase analytics 
                is general and anonymous. This data includes information about noise choice preferences and 
                ATO (auto turn off) times. We use this infomation to improve Fuzzynoise.com.</p>
                
                <div className='spacer_privacy'/>

                <h4 className='miniText_privacy'>Google Firebase may collect personal data.</h4>
                <p className='paragraph_privacy'>As a part of Firebase, Google may collect personal data including 
                but not limited to cookies, location data, and IP addresses in addition to other information for advertising 
                purposes. You can learn more about how Google processes your data by clicking {googlePolicy}.</p>


                <div className='spacer_privacy'/>
                <h4 className='miniText_privacy'>By continuing to use Fuzzynoise.com, you consent to all processes described above. 
                Effective 2021.</h4>

                <h5 className='miniText_privacy'>Contact Us | fuzzynoise.contact@gmail.com - {back}</h5>
            </div>
        </div>
    );
}

export default Privacy;