import {React, useState} from 'react';
import './App-Light.scss';
import { withTheme } from '../Theme';

//MATERIAL UI =========
//button
import Button from '@material-ui/core/Button';

//slider
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';

//radio btns
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//select
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//txt field
import TextField from '@material-ui/core/TextField';

//switch
import Switch from '@material-ui/core/Switch';

//=====================


//FIREBASE --------
import firebase from 'firebase';
import '../Firebase.js';
//-----------------

//PLAYER JS ---------------------
import player from '../Player.js';
//-------------------------------

//PLAYER FUNCTIONS ----------------------
var btn = player.btn;
var volume = player.volume;
var pato = player.ato;
var change = player.change;
//---------------------------------------



//for selector
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: '#414852'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 50,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));


function App(props) {
    //NOTE the majority of functions happens in player.js

    //curently selected sound
    const [selectedSound, updSelectedSound] = useState('rain');
    const [selectedSoundLabel, updSelectedSoundLabel] = useState('Rain with wind.');

    //ATO (Auto Turn Off)
    //const [atoState, togAto] = useState(false);
    const [atoState, togAto] = useState(false);
    const [amt, setAmt] = useState(0);
    const [units, setUnits] = useState('hours');


    function ato(){
        if(!atoState){
            //determine amount of time
            let time;
            if(units === 'hours'){
                //convert hours to ms | 1h = 3600000
                time = amt*3600000;
            }else{
                //convert minutes to ms | 1m = 60000
                time = amt*60000;
            }

            //call ato in player - pass time
            pato(time)
            .then(() => {
                //uncheck switch
                togAto(false);
            });

            console.log('ATO on', amt+' '+units);
            firebase.analytics().logEvent('ATO_On', {time: amt, units: units});//fb
        }else{
            //call ato in player - pass null
            pato(null)
            .then(() => {
                //uncheck switch
                togAto(false);
            });
        }
    }

    //changes selected sound states
    function handleChange(event){
        updSelectedSound(event.target.value);

        switch (event.target.value) {
            case 'rain':
                updSelectedSoundLabel('Rain with wind.');
                change('rain');
                firebase.analytics().logEvent('rain_selected');//fb
                break;
        
            case 'nature':
                updSelectedSoundLabel('Nature.');
                change('nature');
                firebase.analytics().logEvent('nature_selected');//fb
                break;

            case 'fan':
                updSelectedSoundLabel('Fan.');
                change('fan');
                firebase.analytics().logEvent('fan_selected');//fb
                break;

            case 'airplane':
                updSelectedSoundLabel('Airplane.');
                change('airplane');
                firebase.analytics().logEvent('airplane_selected');//fb
                break;

            case 'mars':
                updSelectedSoundLabel('Winds of Mars.');
                change('mars');
                firebase.analytics().logEvent('mars_selected');//fb
                break;

            default:
                break;
        }
    }


    //for selector
    const classes = useStyles();

    //link to privacy policy and email
    var policy = <a className={'link'} href={'/privacy'}>privacy policy</a>;
    
    //link to darkmode
    var darkmode = <a className={'link'} href={'/dark'}>Dark Mode</a>;

    return (
        <div className='background'>
            
            <div className='main'>
                <h1 className='title'>fuzzynoise.com</h1>
                <h3 className='text'>Sounds run infinitely without interuptions.</h3>
                <h3>Why not share? It's free.</h3>

                <h5 className='miniText'>Audio is not streamed. Does not require a continued internet connection.</h5>
                <h5 className='miniText'>By using fuzzynoise.com you agree to our {policy}. | fuzzynoise.contact@gmail.com</h5>
                
                <h5 className='miniText'>{darkmode}</h5>

                <div className='spacer'></div>

                <h2 className='comso'>Play Sound</h2>
                <div className='line'></div>

                <div className='btnDiv'>
                    <h3>{selectedSoundLabel}</h3>
                    {/*<button className='btn' onClick={() => btn()}>Play/Pause</button>*/}
                    <div className='btn'>
                        <Button variant="contained" color="primary" disableElevation onClick={() => {btn()}}>
                            Play/Pause
                        </Button>
                    </div>
                </div>


                <div className='slider'>
                    <Typography id="continuous-slider" gutterBottom>
                        Volume
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <VolumeDown />
                        </Grid>
                        <Grid item xs>
                            <Slider 
                            defaultValue={30}
                            aria-labelledby="continuous-slider" 
                            onChange={(event, value) => {
                                volume(value/100);
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <VolumeUp />
                        </Grid>
                    </Grid>
                </div>

                <div className='radio'>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Select Sound</FormLabel>
                    <RadioGroup aria-label="sounds" value={selectedSound} name="sounds" onChange={event => {handleChange(event)}}>
                        <FormControlLabel value="rain" control={<Radio />} label="Rain with wind." />
                        <FormControlLabel value="nature" control={<Radio />} label="Nature." />
                        <FormControlLabel value="fan" control={<Radio />} label="Fan." />
                        <FormControlLabel value="airplane" control={<Radio />} label="Airplane." />
                        <FormControlLabel value="mars" control={<Radio />} label="Winds of Mars." />
                    </RadioGroup>
                </FormControl>
                </div>
                
                <div className='atoTitle'>
                    <h3>Auto Turn Off</h3>

                    <FormControlLabel
                        control={
                        <Switch
                            //defaultChecked={false}
                            checked={atoState}
                            onChange={event => {togAto(event.target.checked); ato();}}
                            //name="checkedB"
                            color="primary"
                        />
                        }
                        label="On"
                    />
                </div>

                <div className='atoDiv'>
                    <TextField label="amount of time" type='number' variant="outlined" size='medium' 
                    value={amt} onChange={event => setAmt(event.target.value <= 0 ? 0 : event.target.value)}/>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={units ? units : 'hours'}
                            onChange={event => setUnits(event.target.value)}
                        >
                            <MenuItem value={'hours'}>hours</MenuItem>
                            <MenuItem value={'minutes'}>minutes</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='spacer'></div>
            </div>
        </div>
    );
}

export default App;