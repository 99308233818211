import React from "react";

//PAGES
import AppLight from './Pages/App-Light';
import AppDark from './Pages/App-Dark';
import Privacy from './Pages/Privacy';

//REACT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


export default function SiteRouter(){
    return(
        <Router>
            <Switch>

                {/* Privacy Policy */}
                <Route path='/privacy'>
                    <Privacy />
                </Route>

                {/* Dark Theme */}
                <Route path='/dark'>
                    <AppDark />
                </Route>

                {/* Main Page */}
                <Route path='/'>
                    <AppLight />
                </Route>

            </Switch>
        </Router>
    )
}