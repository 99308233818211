import {Howl, Howler} from 'howler';

//sounds | append to permalink -> '?raw=true'
import rain from './sounds/Fuzzy_Rain.mp3';
import nature from './sounds/Fuzzy_Nature.mp3'; //CHANGE FILE!
import fan from './sounds/Fuzzy_Fan.mp3'; //CHANGE FILE!
import airplane from './sounds/Fuzzy_Airplane.mp3'; //CHANGE FILE!
import mars from './sounds/Fuzzy_Mars.mp3'; //CHANGE FILE!


//vars ---------------------
var sound = nh();
var soundId = '';
var vol = 0.3;
var xt = null;
var at = null;
//--------------------------

//return new howl - given a sound id
function nh(sound){
    let h;
    switch (sound) {
        case 'rain':
            h = new Howl({src: [rain]})
            break;
        case 'nature':
            h = new Howl({src: [nature]})
            break;
        case 'fan':
            h = new Howl({src: [fan]})
            break;
        case 'airplane':
            h = new Howl({src: [airplane]})
            break;
        case 'mars':
            h = new Howl({src: [mars]})
            break;

        default:
            h = new Howl({src: [rain]})
            break;
    }
    return h;
}
//---------------


//OP FUNCTIONS ===============================
    //crossfade looper
    function x(){
        console.log('x fade called');

        //set timer to wait for x fade
        xt = setTimeout(() => {
            //check is sound is playing
            if(sound.playing()){

                //start x fade
                console.log('start x fade');

                //1 make old sound = sound2
                let s2 = sound;


                //2 start and fade in new sound
                let s = nh(soundId);

                s.volume(0.0);
                s.play();
                //fade
                Log(s);
                console.log('fading new sound');


                //3 fade out old sound
                nLog(s2);
                console.log('fading old sound');
                
                //4 make sound = to s
                sound = s;

                //5 clear timer
                clearTimeout(xt);

                //6 call x again
                x()

            //}
            //*
            }else{
                console.log('x fade canceled');
                console.log(sound.playing());
            }//*/
        }, 59900);
    }
//*****************************************************
    //Log faders for sounds
    //FADE IN --------------------
    function Log(s){
        var count = 0;

        var i = setInterval(() => {
            count++;
            s.volume(Math.log10(count)*vol);
            //Howler.volume(Math.log10(count)*vol, sound);
            //console.log(Math.log10(count)*vol)
        }, 10);

        setTimeout(() => {
            clearInterval(i)
        }, 100);
    }

    //FADE OUT --------------------
    function nLog(s2){
        var count = 10;

        var i = setInterval(() => {
            count--;
            s2.volume(Math.log10(count)*vol);
            //Howler.volume(Math.log10(count)*vol, sound2);
            //console.log(Math.log10(count)*vol)
        }, 10);

        setTimeout(() => {
            clearInterval(i)
        }, 100);
    }
    //-----------------------------
//============================================


//FUNCTIONS
function btn(){
    if(sound.playing()){
        //stop looper
        Howler.stop();
        clearTimeout(xt);

        console.log('x fade canceled');
    }else{
        sound.volume(vol);
        sound.play();
        //start looper
        x();
    }
} 

function volume(v){
    sound.volume(v);
    vol = v;
}

function ato(time){
    //if time is passed -> start ato else cancel
    //returns promise - if success -> tog switch else nothing

    return new Promise((resolve, reject) => {
        if(time){
            //start timer
            at = setTimeout(() => {
                //fade out sound
                sound.fade(vol, 0.0, 2000);

                //wait for sound to fade
                setTimeout(() => {
                    //stop sound and clear timer
                    Howler.stop();
                    clearTimeout(xt);

                    console.log('ATO activated');

                    resolve();
                }, 2000);
            }, time-2000);
        }else{
            clearTimeout(at);
            console.log('ATO off');

            resolve();
        }
    })
}

function change(noise){
    //stop player
    if(sound.playing()){
        Howler.stop();
        clearTimeout(xt);
    }

    //change noise
    switch (noise){
        case 'rain':
            sound = nh('rain');
            soundId = 'rain';
            break;
        case 'nature':
            sound = nh('nature');
            soundId = 'nature';
            break;
        case 'fan':
            sound = nh('fan');
            soundId = 'fan';
            break;
        case 'airplane':
            sound = nh('airplane');
            soundId = 'airplane';
            break;
        case 'mars':
            sound = nh('mars');
            soundId = 'mars';
        break;

        default:
            sound = nh();
            break;
    }

    console.log('noise changed: '+noise);
}


//EXPORT FUNCTIONS
const player = {
    btn: btn,
    volume: volume,
    ato: ato,
    change: change
}

export default player;