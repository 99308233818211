import app from 'firebase';
import 'firebase/firebase-analytics';

//Firebase Config
const firebaseConfig = {
    apiKey: "AIzaSyDyywemZANFtN-mnIoJXafM06_fHoyUNuA",
    authDomain: "fuzzynoise-80a11.firebaseapp.com",
    projectId: "fuzzynoise-80a11",
    storageBucket: "fuzzynoise-80a11.appspot.com",
    messagingSenderId: "509335802916",
    appId: "1:509335802916:web:c5b7f4d94d16efabc374b1",
    measurementId: "G-30KTSG9YGR"
};

//Initialize Firebase ------------------
var fb = app.initializeApp(firebaseConfig);
var an = app.analytics();